@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styling for modern UI */
body {
  background-color: #ffffff !important; /* White background */
  color: #333; /* Dark text for readability */
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6; /* Improve readability */
}

/* Container styling for central content alignment */
.container {
  max-width: 800px;
  margin: 5px auto;
  padding: 5px;
  background-color: #ffffff; /* Ensure white background */
}

/* Additional styling for the dropdown menu */
.dropdown-menu {
  background-color: #ffffff;
  border-radius: 0.375rem; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Container styling for central content alignment */
.header {
  max-width: 800px;
  margin: 10px auto;
  padding: 10px;
  background-color: #ffffff; /* Ensure white background */
}


/* Button styling */
button {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  padding: 10px 20px;
  border-radius: 8px; /* Rounded corners */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #333; /* Darker black for hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

/* Input and select field styling */
input, select {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px; /* Rounded corners */
  width: 100%; /* Full width */
  background-color: #f9f9f9; /* Light background for input */
  color: #333;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05); /* Subtle inner shadow */
  transition: border-color 0.3s ease;
}

input:focus, select:focus {
  border-color: #000; /* Black border on focus */
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Subtle focus shadow */
}

/* Heading styling */
h1, h2, h3, h4, h5, h6 {
  color: #222; /* Slightly darker text */
  margin-bottom: 20px;
}

/* Modern card style for components */
.card {
  background-color: #ffffff;
  border-radius: 12px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 20px;
}

/* Utility class for subtle contrast */
.text-muted {
  color: #666; /* Lighter gray for muted text */
}

/* Link styling */
a {
  color: #007acc; /* Blue for contrast */
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: #005f99; /* Darker blue on hover */
}

/* Smooth transition for collapsing DateInput upwards */
.transition-all {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

/* Collapsed state */
.max-h-0 {
  max-height: 0;
}

/* Expanded state */
.max-h-screen {
  max-height: 100vh;
}

/* Overflow hidden to prevent content from showing during collapse */
.overflow-hidden {
  overflow: hidden;
}

.characteristics {
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.characteristics h4 {
  margin-bottom: 8px;
}

.characteristics ul {
  margin-bottom: 16px;
}

/* Hide the header only on screen, but include it in the PDF */
.pdf-header {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

@media print {
  .pdf-header {
    visibility: visible;
    position: static;
  }
}

.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25D366;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
}

.whatsapp-button:hover {
  background-color: #20b954;
}

hr {
  margin: 20px 0;
  border: 1px solid #ddd;
}
